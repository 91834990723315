$(function() {
    height = $('.clicct-header').height();
       $(window).scroll(function () {
          if ($(this).scrollTop() > height) {
             $('.clicct-menu').addClass('changeColor')
          }
          if ($(this).scrollTop() < height) {
             $('.clicct-menu').removeClass('changeColor')
          }
       });
    });